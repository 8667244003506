import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import Content_StorageOptimized from "components/content/feature_storage-optimized.js";
import Content_StorageMigration from "components/content/feature_storage-migration.js";

const Page = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      title="Automated GitHub Backup Service"
      description="Automatically backup your GitHub repositories and all metadata. Stay compliant and trust your GitHub data is always available and stored where you want."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">GitHub Backup</span> for amazing coders.</>)}
      subTitle={(
        <>Automatically backup your GitHub repositories and all metadata.<br/>
          Stay compliant and trust your GitHub data is always available and stored where you want.
        </>)}
      icons={[
        <div className="text-base"><i className="fa fa-badge-check text-green"></i><span className="ml-2 font-semibold">Verified by GitHub</span></div>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your GitHub Backup" urlAtrr={{ sb_source: "website", sb_term: `github-hero` }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-github-backup-hero.png" alt="SimpleBackups GitHub backup dashboard: scheduled GitHub Backups"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection text="Trusted by 7000+ developers securing their GitHub data with SimpleBackups" />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                title: (<>Backup all your your <span className="text-primary">GitHub</span> data</>),
                description: (
                  <>
                    <p>
                      Backup all your <span className="link-highlight">GitHub repositories and metadata</span>: issues, pull requests, gists, wikis, projects, releases and more.
                      <br/>
                      <Ui.MiniFaqs items={[
                        {
                          title: 'No-code GitHub backup solution',
                          content: (<>
                            Backup your entire GitHub account. Connect via OAuth and select the resources your want to backup with
                            the highest level of granularity.<br/>
                          </>)
                        },
                        {
                          title: 'Store your backups on your favorite cloud providers',
                          content: (<>Store your backup on your preferred storage providers, SFTP, S3 bucket, or any cloud storage.<br/>
                            Leverage multi-cloud and have storage backup fallback in place.
                            <Ui.FeatureChecks items={['Bring your own storage', 'SimpleStorage', 'S3 Compatible storage', '50+ Cloud Storage services']} />
                          </>)
                        },
                        {
                          title: 'Backup all GitHub data types',
                          content: (<>
                            Select all the data you want to backup, from repositories to all Git metadata.<br/> Filter, exclude or include only the data you need.<br/>
                            <Ui.FeatureChecks cols="3" items={['Repositories', 'Commits', 'Releases', 'Wiki', 'Projects', 'Issues', 'Gists', 'LFS objects', 'All Git metadata']} />
                          </>)
                        },
                      ]} />
                    </p>
                  </>
                ),
                image: (
                  <div className="flex flex-col gap-8">
                    <Ui.Image
                      filename='simplebackups-app-github-backup-all-data.png'
                      alt="Backup all Github repositories and meta data"
                      style={{maxWidth: 450}}
                    />
                    <Sections.StorageProvidersList subject='eee' />
                  </div>
                  )
              },
              {
                title: (<>Trust your backups and stay <span className="text-primary">compliant</span></>),
                description: (
                  <>
                    <p>
                      SimpleBackups is a <span className="link-highlight">security-audited and certified solution</span>. Benefit from the strict European security requirements on all your backups, no matter where you’re
                      based.
                      <br/>

                      <Ui.MiniFaqs items={[
                        {
                          title: 'Team access and role-based permissions',
                          content: (<>Enterprise-ready team authorization, roles, multi-factor authentication and
                            permission granularity.</>)
                        },
                        {
                          title: 'Backup Encryption and end-to-end secured transfers',
                          content: (<>Secure your GitHub backups using AES-256 encryption, even using your own key.</>)
                        },
                        {
                          title: 'Compliance dashboard & audit exports',
                          content: (<>Visualize your backup compliance checklist from a dedicated dashboard. Download
                            your audit reports and
                            documentation. Simplify your ISO, SOC2, HIPPA process with built-in resources.</>)
                        },
                        {
                          title: 'Advanced monitoring features & notifications',
                          content: (<>Detect anomalies and be notified whenever your backups requires attention.
                            <br/>Email, Slack, Discord (...), custom webhook notifications for all your backups and
                            weekly reports for your team.</>)
                        },
                      ]}/>


                      <div className="mt-6">
                        <p className=" font-light text-xs tracking-wider uppercase opacity-75 -mb-2">Ease your Compliance process for:</p>
                        <Ui.FeatureChecks cols="3" items={['ISO27001', 'SOC2', 'HIPAA', 'GDPR', 'ISMS']}/>
                      </div>
                      <Ui.Link arrow="right" to="/security-first/">How we treat your data</Ui.Link>
                    </p>
                  </>
                ),
                image: (
                  <div className="flex flex-col gap-6 items-center justify-center">
                    <Ui.Image
                      filename='simplebackups-app-github-compliance.png'
                      alt="GitHub backup compliance and security features"
                      style={{maxWidth: 450}}
                    />
                    <div className="flex">
                      <div className=" w-32 ">
                        <Ui.Image filename="gdpr-compliant"></Ui.Image>
                      </div>
                      <div className="w-32 ">
                        <Ui.Image filename="iso27001-horizontal.png"></Ui.Image>
                      </div>
                    </div>
                  </div>

                )
              },
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">GitHub Backup <i>as a service</i></Ui.Typography>

          <div className="grid md:grid-cols-12 md:gap-8 gap-4 text-left mt-16">
            <div className="col-span-6 row-span-2 bg-white rounded-xl p-8 pb-0">
              <i className="fad fa-solid fa-clipboard-list-check text-primary text-4xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block">Flexible Backup Strategy</h3>
              <p className="text-base">
                Define when and how often you want to backup your GitHub data.<br/>
                Setup the <span className="link-highlight">grand-father-father-son backup strategy</span> or any custom schedule you need.
                 in a few clicks.<br/>
                <span className="link-highlight">Leverage multi-cloud backups</span> and store your data where you want: SFTP, S3, Drives, Local storage, etc.
              </p>
              <Ui.Image className="mt-4" filename="simplebackups-app-flexible-storage-schedule.png"></Ui.Image>
            </div>
            <div className="col-span-6 bg-white rounded-xl p-8">
              <i className="fad fa-solid fa-shield-check text-primary text-4xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block">Disaster Recovery</h3>
              <p className="text-base">
                  Worry-free GitHub backup recovery with clear steps and automation: anyone in your team can restore your data at any time.<br/>
                No guessing, <span className="link-highlight">pick a backup and restore it in a few clicks</span>.
              </p>
            </div>
            <div className="col-span-6 bg-white rounded-xl p-8">
              <i className="fad fa-fingerprint text-primary text-4xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block">Compliance & Audit ready</h3>
              <p className="text-base">
                We got you covered with our <span className="link-highlight">compliance dashboard</span> and audit exports helping you getting
                GDPR, HIPAA, SOC2, ISO27001 compliant smoothly.
                <br/> SimpleBakcups provides all the tools and documentation you need to pass your audits.
              </p>
            </div>
            <div className="col-span-3 bg-white rounded-xl p-8">
              <i className="fad fa-binary-lock text-primary text-3xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block">Encryption</h3>
              <p className="text-base">No-brainer full encryption for all your backups with your own enryption key.</p>
            </div>
            <div className="col-span-3 bg-white rounded-xl p-8">
              <i className="fad fa-rectangle-code text-primary text-3xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block ">API & Automation</h3>
              <p className="text-base">Automate your backups in your existing pipeline with Webhooks and API.</p>
            </div>
            <div className="col-span-3 bg-white rounded-xl p-8">
              <i className="fad fa-bell text-primary text-3xl mb-4 "/>
              <h3 className="text-xl font-bold mb-1 h-8 block ">Anomaly detection</h3>
              <p className="text-base">Get notified with built-in monitoring, notifications and reporting.</p>
            </div>
            <div className="col-span-3 bg-white rounded-3l p-8">
              <i className="fad fa-user-ninja text-primary text-3xl mb-4"/>
              <h3 className="text-xl font-bold mb-1 h-8 block text-wr">No-robot support</h3>
              <p className="text-base">Get human support from a team of experts, available by chat.</p>
            </div>
          </div>


        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Learn more about GitHub backup</>}
      text={`Find out more and get started with our GitHub  Backup guides`}
      tags={['saas-backup', 'saas-github']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended tags={['support']}/>
    <Sections.SectionFaq color="alt" terms={['github']}/>

  </Layout.Layout>
)

export default Page
